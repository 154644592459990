var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-view p-3"},[_c('div',{staticClass:"p-3"},[_c('CiHeading',{staticClass:"mb-3",attrs:{"level":"1"}},[_vm._v(" "+_vm._s(_vm.$t("settings.header"))+" ")]),_c('RdxDivider',{staticClass:"mb-5"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 col-xl-3"},[_c('ul',{staticClass:"settings-view__nav"},[_c('li',{staticClass:"settings-view__nav-li"},[_c('router-link',{staticClass:"settings-view__nav-link",class:{
                'settings-view__nav-link--active': _vm.isCurrentRoute(
                  'CarsInventorySettingsProfile'
                )
              },attrs:{"to":{ name: 'CarsInventorySettingsProfile' },"tag":"span"}},[_vm._v(" "+_vm._s(_vm.$t("settings.profile"))+" ")])],1),_c('li',{staticClass:"settings-view__nav-li"},[_c('router-link',{staticClass:"settings-view__nav-link",class:{
                'settings-view__nav-link--active': _vm.isCurrentRoute(
                  'CarsInventorySettingsLocation'
                )
              },attrs:{"to":{ name: 'CarsInventorySettingsLocation' },"tag":"span"}},[_vm._v(" "+_vm._s(_vm.$t("settings.location-address"))+" ")])],1),_c('li',{staticClass:"settings-view__nav-li"},[_c('router-link',{staticClass:"settings-view__nav-link",class:{
                'settings-view__nav-link--active': _vm.isCurrentRoute(
                  'Dictionary'
                )
              },attrs:{"to":{ name: 'Dictionary' },"tag":"span"}},[_vm._v(" "+_vm._s(_vm.$t("settings.option-codes-library"))+" ")])],1),_c('li',{staticClass:"settings-view__nav-li"},[_c('router-link',{staticClass:"settings-view__nav-link",class:{
                'settings-view__nav-link--active': _vm.isCurrentRoute(
                  'CarsInventorySettingsUnits'
                )
              },attrs:{"to":{ name: 'CarsInventorySettingsUnits' },"tag":"span"}},[_vm._v(" "+_vm._s(_vm.$t("settings.units"))+" ")])],1)])]),_c('div',{staticClass:"col-12 col-lg-6 col-xl-9"},[_c('div',{staticClass:"col-xl-8 p-0"},[_c('router-view',{attrs:{"options":_vm.options,"companies":_vm.companies,"selected-company":_vm.selectedCompany,"pending":_vm.pending},on:{"saveSettings":function($event){return _vm.onUpdateSettings($event)}}})],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }