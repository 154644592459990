<template>
  <div class="settings-view p-3">
    <div class="p-3">
      <CiHeading level="1" class="mb-3">
        {{ $t("settings.header") }}
      </CiHeading>
      <RdxDivider class="mb-5" />

      <div class="row">
        <div class="col-12 col-lg-6 col-xl-3">
          <ul class="settings-view__nav">
            <li class="settings-view__nav-li">
              <router-link
                :to="{ name: 'CarsInventorySettingsProfile' }"
                tag="span"
                class="settings-view__nav-link"
                :class="{
                  'settings-view__nav-link--active': isCurrentRoute(
                    'CarsInventorySettingsProfile'
                  )
                }"
              >
                {{ $t("settings.profile") }}
              </router-link>
            </li>
            <li class="settings-view__nav-li">
              <router-link
                :to="{ name: 'CarsInventorySettingsLocation' }"
                tag="span"
                class="settings-view__nav-link"
                :class="{
                  'settings-view__nav-link--active': isCurrentRoute(
                    'CarsInventorySettingsLocation'
                  )
                }"
              >
                {{ $t("settings.location-address") }}
              </router-link>
            </li>
            <li class="settings-view__nav-li">
              <router-link
                :to="{ name: 'Dictionary' }"
                tag="span"
                class="settings-view__nav-link"
                :class="{
                  'settings-view__nav-link--active': isCurrentRoute(
                    'Dictionary'
                  )
                }"
              >
                {{ $t("settings.option-codes-library") }}
              </router-link>
            </li>
            <li class="settings-view__nav-li">
              <router-link
                :to="{ name: 'CarsInventorySettingsUnits' }"
                tag="span"
                class="settings-view__nav-link"
                :class="{
                  'settings-view__nav-link--active': isCurrentRoute(
                    'CarsInventorySettingsUnits'
                  )
                }"
              >
                {{ $t("settings.units") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-6 col-xl-9">
          <div class="col-xl-8 p-0">
            <router-view
              :options="options"
              :companies="companies"
              :selected-company="selectedCompany"
              :pending="pending"
              @saveSettings="onUpdateSettings($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { serialise } from "kitsu-core";

import { RdxDivider } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

import payloadRewriter from "@/utils/payload-rewriter";
import { customAtob } from "@/store/base64";

export default {
  name: "SettingsView",

  components: {
    RdxDivider,
    CiHeading
  },

  data() {
    return {
      pending: {
        load: false,
        save: false
      },
      companies: [],
      company: {
        address: null,
        city: null,
        contact_details: null,
        currency: null,
        email: null,
        engine_capacity_unit: null,
        locale: null,
        mileage_unit: null,
        name: null,
        opening_hours: null,
        phone: null,
        power_unit: null,
        timezone: null,
        uuid: null,
        zipcode: null,
        skip_company_name_in_location: null
      },

      options: {},
      selectedCompany: {}
    };
  },

  created() {
    this.getCompanies();
  },

  methods: {
    getCompanies() {
      this.pending.load = true;

      this.$http
        .get("/companies", {
          headers: {
            "X-Company-UUID": customAtob(
              window.localStorage.getItem("main_company_uuid")
            )
          }
        })
        .then(response => {
          this.companies = response.data.data;
        })
        .then(() => {
          this.selectedCompany = this.companies.find(company => {
            return (
              company.attributes.uuid ===
              customAtob(window.localStorage.getItem("company_uuid"))
            );
          });
        })
        .then(() => {
          payloadRewriter(this.company, this.selectedCompany.attributes);
          this.company.id = this.selectedCompany.id;
          this.options = this.selectedCompany.meta;
        })
        .then(() => {
          this.pending.load = false;
        });
    },

    onUpdateSettings(payload) {
      this.company = payload;
      this.pending.save = true;
      const SETTINGS = serialise("companies", {
        ...this.company
      });

      this.$http
        .patch("companies/" + this.company.id, SETTINGS)
        .then(() => {
          this.getCompanies();
          this.$toast.success(this.$t("settings.succesfully-updated"));
        })
        .finally(() => (this.pending.save = false));
    },

    isCurrentRoute(routeName) {
      const { name } = this.$route;
      return !!(name === routeName);
    }
  }
};
</script>

<style lang="scss">
.settings-view {
  &__grid {
    display: grid;
    grid-template-columns: 25% 75%;
    > div {
      padding: 0rem 2rem;
    }
  }
  &__checkbox {
    margin-bottom: 0;
    margin-right: 0.5em;
  }
  &__checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
  }
  &__nav {
    list-style: none;
    position: sticky;
    top: 0;
  }
  &__nav-li {
    margin-bottom: 10px;
  }
  &__nav-link {
    padding: 10px 24px;
    cursor: pointer;
    &:hover,
    &--active {
      color: $blue-6;
    }
  }
}
</style>
