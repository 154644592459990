<template>
  <ValidationObserver ref="new-record-form" v-slot="{ valid }">
    <RdxModal class="dictionary__new-record" @close-modal="cancel">
      <template #header>
        {{ $t("dictionary.new-record.header") }}
      </template>

      <VRdxInput
        v-model="code"
        :name="$t('dictionary.new-record.code-label')"
        :label="$t('dictionary.new-record.code-label')"
        rules="required"
      />

      <VRdxTextarea
        v-model="description"
        :name="$t('dictionary.new-record.description-label')"
        :label="$t('dictionary.new-record.description-label')"
        rules="required"
      />

      <RdxSelect
        v-model="category"
        :max-height="190"
        open-direction="top"
        :options="categories"
        :label="$t('dictionary.new-record.category-label')"
        :placeholder="$t('dictionary.new-record.category-placeholder')"
      >
        <template #singleLabel="{ option }">
          {{ $t(`global.equipment-options.${option}`) }}
        </template>
        <template #option="{ option }">
          {{ $t(`global.equipment-options.${option}`) }}
        </template>
      </RdxSelect>

      <template #footer>
        <RdxButton outline @click="cancel">
          <span>{{ $t("global.cancel") }}</span>
        </RdxButton>
        <RdxButton filled variant="blue" :disabled="!valid" @click="confirm">
          <span>{{ $t("global.save") }}</span>
        </RdxButton>
      </template>
    </RdxModal>
  </ValidationObserver>
</template>

<script>
import {
  RdxModal,
  RdxInput,
  RdxTextarea,
  RdxButton,
  RdxSelect
} from "@raffine/rdx-ui/lib/rdx-ui.umd";

import { withValidation } from "vee-validate";
const VRdxInput = withValidation(RdxInput, ({ errors }) => ({ errors }));
const VRdxTextarea = withValidation(RdxTextarea, ({ errors }) => ({ errors }));

import FEATURES_CATEGORIES from "@/resources/features-categories";

export default {
  name: "NewRecord",

  components: {
    RdxModal,
    RdxButton,
    RdxSelect,

    VRdxInput,
    VRdxTextarea
  },

  data() {
    return {
      code: null,
      description: null,
      category: null,
      categories: FEATURES_CATEGORIES
    };
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$refs["new-record-form"].validate().then(success => {
        if (success) {
          this.$http
            .post("/vocabulary", {
              data: {
                attributes: {
                  code: this.code,
                  label: this.description,
                  category: this.category
                }
              }
            })
            .then(({ data: { data } }) => {
              this.$emit("Dictionary:NewRecord", data);
              this.cancel();
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.dictionary__new-record {
  .rdx-modal-box__content {
    padding: 1.5rem 2rem;
  }
}
</style>
