<template>
  <section class="ci-dictionary">
    <CiHeading level="1" class="mb-3">
      {{ $t("dictionary.header") }}
    </CiHeading>
    <div class="ci-dictionary__header mb-4">
      <div class="ci-dictionary__actions">
        <RdxInput
          v-model="searchString"
          class="ci-dictionary__search mb-0"
          type="search"
          :placeholder="$t('dictionary.search-placeholder')"
        >
          <template #prefix>
            <RdxIcon icon="ri-search-line" />
          </template>
        </RdxInput>
        <RdxButton
          filled
          variant="blue"
          class="ci-dictionary__actions--add-new ml-4"
          data-cy="new-record"
          @click="newRecordModalIsOpen = true"
        >
          <RdxIcon icon="ri-add-line mr-1" />
          <span>{{ $t("dictionary.add-new-record-button") }}</span>
        </RdxButton>
      </div>
    </div>
    <div v-if="loading" class="ci-dictionary__loader">
      <RdxLoader size="large" />
    </div>
    <CiEmptyState v-else-if="!filteredTranslations.length">
      <template #header>
        {{ $t("dictionary.search-no-results.header") }}
      </template>
      <template #description>
        {{ $t("dictionary.search-no-results.description") }}
      </template>
    </CiEmptyState>
    <CiTable v-else class="ci-dictionary__table ci-table--hover">
      <tr>
        <td>
          {{ $t("dictionary.code") }}
        </td>
        <td>
          {{ $t("dictionary.orginal-description") }}
        </td>
        <td>
          {{ $t("dictionary.translation") }}
        </td>
        <td>
          {{ $t("dictionary.category") }}
        </td>
        <td />
      </tr>
      <CiDictionaryTableRow
        v-for="(row, i) in filteredTranslations"
        :key="`row-${i}`"
        :row="row"
        @submit-translation="submitTranslation(row)"
        @Dictionary:RemoveRecord="showConfirmationModal($event)"
      />
    </CiTable>

    <NewRecord
      v-if="newRecordModalIsOpen"
      data-cy="new-record-modal"
      @cancel="newRecordModalIsOpen = false"
      @Dictionary:NewRecord="addNewRecord($event)"
    />

    <CiDeleteModal
      v-if="showRemoveConfirmationModal"
      :heading="$t('dictionary.remove-record-modal.header')"
      :paragraph="$t('dictionary.remove-record-modal.description')"
      :delete-text="$t('dictionary.remove-record-modal.confirm-button')"
      :cancel-text="$t('global.cancel')"
      @delete="deleteTranslation"
      @cancel="hideConfirmationModal"
    />
  </section>
</template>

<script>
import {
  RdxLoader,
  RdxInput,
  RdxIcon,
  RdxButton
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiDictionaryTableRow from "@/components/molecules/CiDictionaryTableRow";
import CiDeleteModal from "@/components/CiDeleteModal/CiDeleteModal";
import CiHeading from "@/components/atoms/CiHeading";
import CiTable from "@/components/molecules/CiTable";
import CiEmptyState from "@/components/molecules/CiEmptyState";

import NewRecord from "./NewRecord";

export default {
  name: "DictionaryView",

  components: {
    RdxLoader,
    RdxButton,
    RdxInput,
    RdxIcon,

    CiDictionaryTableRow,
    CiDeleteModal,
    CiHeading,
    CiTable,
    CiEmptyState,

    NewRecord
  },

  data() {
    return {
      dictionaryData: [],
      loading: true,
      searchString: "",
      showRemoveConfirmationModal: false,
      entryIdToRemove: null,
      newRecordModalIsOpen: false
    };
  },

  computed: {
    filteredTranslations() {
      if (!this.searchString) return this.dictionaryData;

      let lowerCaseSearchString = this.searchString.toLowerCase();

      return this.dictionaryData.filter(
        ({
          attributes: { category, code, original_description, translation }
        }) => {
          let searchAttributes = {
            category: category
              ? this.$t(`global.equipment-options.${category}`)
              : "",
            code,
            original_description,
            translation
          };
          let searchData = Object.values(searchAttributes)
            .join(" ")
            .toLowerCase();
          return searchData.includes(lowerCaseSearchString);
        }
      );
    }
  },

  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get("/vocabulary")
        .then(response => {
          this.dictionaryData = response.data.data;
        })
        .then(() => {
          this.loading = false;
        });
    },

    submitTranslation(row) {
      let translationData = {
        data: {
          attributes: {
            category: row.attributes.category,
            label: row.attributes.translation
          }
        }
      };
      this.$http
        .patch(`/vocabulary/${row.attributes.code}`, translationData)
        .then(() => {
          this.$toast.success(
            this.$t("dictionary.translation-update-successfull", {
              code: row.attributes.code
            })
          );
        })
        .catch(() => {
          this.$toast.error(
            this.$t("dictionary.translation-update-failed", {
              code: row.attributes.code
            })
          );
          row.attributes.translation = this.storedValue;
        });
    },

    showConfirmationModal($event) {
      this.showRemoveConfirmationModal = true;
      this.entryIdToRemove = $event;
    },

    hideConfirmationModal() {
      this.showRemoveConfirmationModal = false;
      this.entryIdToRemove = null;
    },

    deleteTranslation() {
      let entryId = this.entryIdToRemove;
      this.$http.delete(`vocabulary/${entryId}`).then(() => {
        let entryIndex = this.dictionaryData
          .map(({ id }) => id)
          .indexOf(entryId);
        this.$delete(this.dictionaryData, entryIndex);
      });
    },

    addNewRecord(record) {
      this.dictionaryData.push(record);
      this.$toast.success(this.$t("dictionary.add-new-record-success-message"));
    }
  }
};
</script>

<style lang="scss">
.ci-dictionary {
  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    &--add-new {
      margin-left: auto;
    }
  }
  &__search {
    min-width: 400px;
    flex-grow: 1;
  }
  &__table {
    table-layout: auto;
    margin-bottom: 1em;
    td {
      &:first-child {
        width: 100px;
      }
      &:nth-child(4),
      &:nth-child(5) {
        width: 1%;
        white-space: nowrap;
      }
    }
  }
  &__input {
    margin: 0;
    padding: 0;

    * {
      border: 0 !important;
      border-radius: 0 !important;
      padding: 0;
    }
  }
  .rdx-modal {
    position: fixed;
  }
}
</style>
