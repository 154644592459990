<template>
  <table class="ci-table">
    <slot />
  </table>
</template>

<script>
export default {
  name: "CiTable"
};
</script>

<style lang="scss">
.ci-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  color: $grey-9;
  tr:first-child {
    background: $grey-2;
    font-weight: 600;
    td {
      padding: 8px 7px;
      &:first-child {
        padding: 8px 10px;
      }
    }
  }
  td {
    border: 1px solid $grey-4;
    padding: 8px 10px;
    &:first-child {
      width: 38px;
    }
  }

  // &__cell {
  //   &--vertically-aligned {
  //     display: flex;
  //     align-items: center;
  //     span {
  //       margin-right: 5px;
  //     }
  //   }
  // }
}
</style>
