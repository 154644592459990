<!-- eslint-disable vue/no-mutating-props -->
<template>
  <tr>
    <td>{{ row.attributes.code }}</td>
    <td>{{ row.attributes.original_description }}</td>
    <td>
      <RdxTextarea
        v-model="row.attributes.translation"
        class="ci-dictionary__input"
        @focus="storeValue(row.attributes.translation)"
        @blur="submitTranslation"
        @keydown.enter="blurInput($event)"
      />
    </td>
    <td>
      {{
        row.attributes.category
          ? $t(`global.equipment-options.${row.attributes.category}`)
          : ""
      }}
    </td>
    <td>
      <RdxButton
        rect
        class="ci-specs-table__toolbar-button"
        @click="$emit('Dictionary:RemoveRecord', row.id)"
      >
        <RdxIcon class="ri-delete-bin-7-line rdx-icon--18" />
      </RdxButton>
    </td>
  </tr>
</template>

<script>
import {
  RdxTextarea,
  RdxButton,
  RdxIcon
} from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiDictionaryTableRow",

  components: {
    RdxTextarea,
    RdxIcon,
    RdxButton
  },

  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      storedValue: ""
    };
  },

  methods: {
    storeValue(value) {
      this.storedValue = value;
    },
    submitTranslation() {
      if (this.row.attributes.translation !== this.storedValue) {
        this.$emit("submit-translation");
      }
    },

    blurInput(event) {
      event.target.blur();
    }
  }
};
</script>
